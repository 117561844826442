import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Paper,
  TextField,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import {
  CalendarIcon,
  CheckedCheckboxIcon,
  CloseIcon,
  FilterIcon,
  GenerateExcelIcon,
  SelectPopupIcon,
  UncheckedCheckboxIcon,
} from 'assets/icons';
import {FC, useState} from 'react';
import {
  addBranchInEdit,
  clearFiltersButton,
} from '../../../styles/MUIStyles/buttons';
import {
  calendarStyles,
  chipStyles,
  searchAndSelect,
} from 'styles/MUIStyles/common';

import ButtonWithProgress from '../../../components/ButtonWithProgress/ButtonWithProgress';
import {DateTimePicker} from '@mui/x-date-pickers';
import {IGetBranchesListItem} from 'store/models/branches';
import {IGetCompaniesListItem} from 'store/models/companies';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';
import {ReportTypesItem} from 'store/models/reports';
import {StationsListItem} from 'store/models/stations';
import dayjs from 'dayjs';
import styles from '../Reports.module.scss';

interface Props {
  setReportStations: (station: StationsListItem[] | null) => void;
  stations: StationsListItem[];
  stationsList: StationsListItem[];
  setReportType: (type: ReportTypesItem | null) => void;
  reportTypes: ReportTypesItem[];
  reportType: ReportTypesItem;
  setReportsStartDateTime: (date: string | null) => void;
  reportsStartDateTime: string | null;
  setReportsEndDateTime: (date: string | null) => void;
  reportsEndDateTime: string | null;
  fetchTableData: () => void;
  isReportsDataFetching: boolean;
  companies: IGetCompaniesListItem[];
  reportCompanies: IGetCompaniesListItem[];
  setReportCompanies: (companies: IGetCompaniesListItem[]) => void;
  branches: IGetBranchesListItem[];
  reportBranches: IGetBranchesListItem[];
  setReportBranches: (branches: IGetBranchesListItem[]) => void;
  setShouldSaveReportType: (shouldSave: boolean) => void;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  // dealerBackupReportFilters
  //  lastZReportDate
  dealerBackupReportFiltersLastZReportDateFrom: string | null;
  setDealerBackupReportFiltersLastZReportDateFrom: (
    date: string | null,
  ) => void;
  dealerBackupReportFiltersLastZReportDateTo: string | null;
  setDealerBackupReportFiltersLastZReportDateTo: (date: string | null) => void;
  //  lastReceivedMainBackupDate
  dealerBackupReportFiltersLastReceivedMainBackupDateFrom: string | null;
  setDealerBackupReportFiltersLastReceivedMainBackupDateFrom: (
    date: string | null,
  ) => void;
  dealerBackupReportFiltersLastReceivedMainBackupDateTo: string | null;
  setDealerBackupReportFiltersLastReceivedMainBackupDateTo: (
    date: string | null,
  ) => void;
  //  lastReceivedNonMainBackupDate
  dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom: string | null;
  setDealerBackupReportFiltersLastReceivedNonMainBackupDateFrom: (
    date: string | null,
  ) => void;
  dealerBackupReportFiltersLastReceivedNonMainBackupDateTo: string | null;
  setDealerBackupReportFiltersLastReceivedNonMainBackupDateTo: (
    date: string | null,
  ) => void;
  //   lastDCRequestDate
  dealerBackupReportFiltersLastDCRequestDateFrom: string | null;
  setDealerBackupReportFiltersLastDCRequestDateFrom: (
    date: string | null,
  ) => void;
  dealerBackupReportFiltersLastDCRequestDateTo: string | null;
  setDealerBackupReportFiltersLastDCRequestDateTo: (
    date: string | null,
  ) => void;
}

const selectAllOption = {
  id: 'all',
  name: 'All',
  posChassicNumber: '',
};
const ReportsTopToolbar: FC<Props> = (props) => {
  const [isShowFilterButtonOpen, setIsShowFilterButtonOpen] = useState(false);
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: '#8A8894',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
              borderRadius: '12px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #3152C7',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          {/* reportsStartDateTime */}
          <DateTimePicker
            inputFormat="DD.MM.YY HH:mm"
            ampm={false}
            minutesStep={1}
            disabled={props.reportType?.name === 'DEALER_BACKUP_REPORT'}
            maxDate={props.reportsEndDateTime}
            value={props.reportsStartDateTime}
            components={{
              OpenPickerIcon: CalendarIcon,
            }}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
                style: {justifyContent: 'flex-end'},
              },
            }}
            onChange={(newValue) => {
              if (dayjs(newValue).isValid()) {
                console.log(newValue);

                props.setReportsStartDateTime(dayjs(newValue).utc().format());
              }

              if (newValue === null) {
                props.setReportsStartDateTime(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{...calendarStyles, width: '22.25%'}}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'Select start date',
                  autoComplete: 'off',
                }}
              />
            )}
          />
          {/* reportsEndDateTime */}
          <DateTimePicker
            inputFormat="DD.MM.YY HH:mm"
            ampm={false}
            minutesStep={1}
            disabled={props.reportType?.name === 'DEALER_BACKUP_REPORT'}
            minDate={props.reportsStartDateTime}
            value={props.reportsEndDateTime}
            components={{
              OpenPickerIcon: CalendarIcon,
            }}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
                style: {justifyContent: 'flex-end'},
              },
            }}
            onChange={(newValue) => {
              if (dayjs(newValue).isValid()) {
                props.setReportsEndDateTime(dayjs(newValue).utc().format());
              }

              if (newValue === null) {
                props.setReportsEndDateTime(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{...calendarStyles, width: '22.25%'}}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'Select end date',
                  autoComplete: 'off',
                }}
              />
            )}
          />
          {/* reportType */}
          <Autocomplete
            disablePortal
            options={props.reportTypes || []}
            value={props.reportType}
            onChange={(_, value) => {
              if (value) {
                props.setReportType(value);
              } else {
                props.setReportType(null);
                props.setShouldSaveReportType(false);
              }
            }}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{width: '22.25%'}}
            ListboxProps={{
              style: {maxHeight: '200px', padding: 0},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value?.name === '' ? true : option?.name === value?.name
            }
            getOptionLabel={(option) => option?.name || ''}
            popupIcon={<SelectPopupIcon />}
            renderOption={(props: any, option) => {
              return (
                <span {...props} className={styles.dropDownItem}>
                  {option?.name}
                </span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select report type"
                sx={searchAndSelect}
              />
            )}
          />
          {/* reportStations */}
          {props.reportType?.name === 'DEALER_Z_SKIPPED_REPORT' ? (
            <Autocomplete
              key={props.reportType?.name}
              multiple
              disablePortal
              disableCloseOnSelect
              limitTags={1}
              options={props.stationsList}
              value={props.stations}
              onChange={(_, value) => {
                if (
                  value?.some((station) => station.id === selectAllOption?.id)
                ) {
                  props.setReportStations(
                    value?.filter(
                      (station) => station.id !== selectAllOption?.id,
                    ),
                  );
                } else {
                  props.setReportStations(value);
                }
              }}
              PaperComponent={({children, ...restPaperProps}) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                    minWidth: '22.25%',
                  }}
                  {...restPaperProps}
                >
                  <Box
                    onMouseDown={(e) => e.preventDefault()}
                    sx={{
                      width: '100%',
                      padding: ' 12px 20px 12px',
                    }}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        props.setReportStations(
                          props.stations[0]?.id === selectAllOption?.id
                            ? []
                            : [selectAllOption, ...props.stationsList],
                        );
                      }}
                      label="All"
                      control={
                        <Checkbox
                          id="select-all-checkbox"
                          icon={<UncheckedCheckboxIcon />}
                          checkedIcon={<CheckedCheckboxIcon />}
                          style={{marginRight: 8}}
                          checked={
                            props.stations[0]?.id === selectAllOption?.id
                          }
                        />
                      }
                    />
                  </Box>
                  {children}
                </Paper>
              )}
              sx={{width: '22.25%'}}
              ListboxProps={{
                style: {
                  maxHeight: '300px',
                  padding: 0,
                },
                className: 'autoCompleteListBox',
              }}
              getOptionLabel={(option) =>
                `${option?.name}/${option?.posChassicNumber}`
              }
              isOptionEqualToValue={(option, value) =>
                value?.id === '' ? true : option?.id === value?.id
              }
              popupIcon={<SelectPopupIcon />}
              renderTags={(tagValue, getTagProps) => (
                <Box
                  sx={{
                    display: 'flex',
                    overflow: 'auto',
                    width: '70%',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                >
                  {tagValue?.map((option, index) => (
                    <Chip
                      {...getTagProps({index})}
                      size="small"
                      label={option?.name}
                      sx={{
                        ...chipStyles,
                        maxWidth: '150px !important',
                      }}
                    />
                  ))}
                </Box>
              )}
              renderOption={(props: any, option, {selected}) => {
                return (
                  <span {...props} className={styles.dropDownItem}>
                    <Checkbox
                      icon={<UncheckedCheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option?.name}/{option?.posChassicNumber}
                  </span>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select station/posChassisNumber"
                  sx={searchAndSelect}
                />
              )}
            />
          ) : (
            <Autocomplete
              key={props.reportType?.name}
              disablePortal
              disabled={props.reportType?.name === 'DEALER_BACKUP_REPORT'}
              options={props.stationsList}
              value={props.stations[0]}
              onChange={(_, value) => {
                if (value) {
                  props.setReportStations([value]);
                } else {
                  props.setReportStations([]);
                }
              }}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                    minWidth: '400px',
                  }}
                  {...props}
                />
              )}
              sx={{width: '22.25%'}}
              ListboxProps={{
                style: {maxHeight: '300px', padding: 0},
                className: 'autoCompleteListBox',
              }}
              getOptionLabel={(option) =>
                `${option?.name}/${option?.posChassicNumber}`
              }
              isOptionEqualToValue={(option, value) =>
                value?.id === '' ? true : option?.id === value?.id
              }
              popupIcon={<SelectPopupIcon />}
              renderOption={(props: any, option) => {
                return (
                  <span {...props} className={styles.dropDownItem}>
                    {option?.name}/{option?.posChassicNumber}
                  </span>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select station/posChassisNumber"
                  sx={searchAndSelect}
                />
              )}
            />
          )}
          <ButtonWithProgress
            loading={props.isReportsDataFetching}
            disabled={props.isReportsDataFetching}
            startIcon={
              props.isReportsDataFetching ? (
                <LoadingIconSpacer width={'20px'} height={'20px'} />
              ) : (
                <GenerateExcelIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    filter:
                      'invert(24%) sepia(76%) saturate(2109%) hue-rotate(218deg) brightness(91%) contrast(90%)',
                  }}
                />
              )
            }
            sx={{...addBranchInEdit, width: '10%', mb: 0}}
            onClick={props.fetchTableData}
          >
            Generate
          </ButtonWithProgress>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          {/* reportCompanies */}
          <Autocomplete
            multiple
            limitTags={1}
            options={props.companies || []}
            loading={false}
            size="small"
            popupIcon={<SelectPopupIcon />}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{width: '25%'}}
            value={props.reportCompanies}
            ListboxProps={{
              style: {maxHeight: '300px'},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value.name === '' ? true : option.id === value.id
            }
            getOptionLabel={(option) => (option?.name ? option.name : '')}
            onChange={(_, value) => {
              props.setReportCompanies([]);

              if (value) {
                props.setReportCompanies(value);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({index})}
                  size="small"
                  label={option.name}
                  sx={chipStyles}
                  deleteIcon={
                    <CloseIcon style={{width: '24px', height: '24px'}} />
                  }
                />
              ))
            }
            renderOption={(props: any, option, {selected}) => (
              <span {...props} className={styles.dropDownItem}>
                <Checkbox
                  icon={<UncheckedCheckboxIcon />}
                  checkedIcon={<CheckedCheckboxIcon />}
                  style={{marginRight: 8}}
                  checked={selected}
                />
                {option.name}
              </span>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select Company"
                sx={searchAndSelect}
              />
            )}
          />
          {/* reportBranches */}
          <Autocomplete
            multiple
            limitTags={1}
            options={props.branches || []}
            loading={false}
            size="small"
            popupIcon={<SelectPopupIcon />}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{width: '25%'}}
            value={props.reportBranches}
            ListboxProps={{
              style: {maxHeight: '300px'},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value.name === '' ? true : option.id === value.id
            }
            getOptionLabel={(option) => (option?.name ? option.name : '')}
            onChange={(_, value) => {
              if (value) {
                props.setReportBranches(value);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({index})}
                  size="small"
                  label={option.name}
                  sx={chipStyles}
                  deleteIcon={
                    <CloseIcon style={{width: '24px', height: '24px'}} />
                  }
                />
              ))
            }
            renderOption={(props: any, option, {selected}) => (
              <span {...props} className={styles.dropDownItem}>
                <Checkbox
                  icon={<UncheckedCheckboxIcon />}
                  checkedIcon={<CheckedCheckboxIcon />}
                  style={{marginRight: 8}}
                  checked={selected}
                />
                {option.name}
              </span>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select Branch"
                sx={searchAndSelect}
              />
            )}
          />
          <FormControlLabel
            sx={{width: '20%'}}
            control={
              <Checkbox
                disabled={props.reportType?.name !== 'DEALER_BACKUP_REPORT'}
                icon={<UncheckedCheckboxIcon />}
                checkedIcon={<CheckedCheckboxIcon />}
                style={{marginRight: 8}}
                checked={props.isActive}
                onChange={() => props.setIsActive(!props.isActive)}
              />
            }
            label="isActive"
          />
          {props.reportType?.name === 'DEALER_BACKUP_REPORT' ? (
            <Button
              variant="text"
              startIcon={
                <FilterIcon
                  style={{
                    width: '16px',
                    height: '16px',
                    filter:
                      'invert(25%) sepia(81%) saturate(1632%) hue-rotate(213deg) brightness(95%) contrast(95%)',
                  }}
                />
              }
              sx={clearFiltersButton}
              onClick={() => {
                setIsShowFilterButtonOpen((prevState) => !prevState);
              }}
            >
              {isShowFilterButtonOpen ? 'Hide filters' : 'Show filters'}
            </Button>
          ) : null}
        </Box>
        {/* Dealer backup report filters */}
        {isShowFilterButtonOpen &&
        props.reportType?.name === 'DEALER_BACKUP_REPORT' ? (
          <>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <DateTimePicker
                label="Last Z report date from"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                maxDate={props?.dealerBackupReportFiltersLastZReportDateTo}
                value={props.dealerBackupReportFiltersLastZReportDateFrom}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastZReportDateFrom(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastZReportDateFrom(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />

              <DateTimePicker
                label="Last Z report date to"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                minDate={props.dealerBackupReportFiltersLastZReportDateFrom}
                value={props.dealerBackupReportFiltersLastZReportDateTo}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastZReportDateTo(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastZReportDateTo(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
              <DateTimePicker
                label="Last received main backup date from"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                maxDate={
                  props.dealerBackupReportFiltersLastReceivedMainBackupDateTo
                }
                value={
                  props.dealerBackupReportFiltersLastReceivedMainBackupDateFrom
                }
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastReceivedMainBackupDateFrom(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastReceivedMainBackupDateFrom(
                      null,
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
              <DateTimePicker
                label="Last received main backup date to"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                minDate={
                  props.dealerBackupReportFiltersLastReceivedMainBackupDateFrom
                }
                value={
                  props.dealerBackupReportFiltersLastReceivedMainBackupDateTo
                }
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastReceivedMainBackupDateTo(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastReceivedMainBackupDateTo(
                      null,
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
            </Box>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <DateTimePicker
                label="Last received non-main backup date from"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                maxDate={
                  props.dealerBackupReportFiltersLastReceivedNonMainBackupDateTo
                }
                value={
                  props.dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom
                }
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastReceivedNonMainBackupDateFrom(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastReceivedNonMainBackupDateFrom(
                      null,
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
              <DateTimePicker
                label="Last received non-main backup date to"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                minDate={
                  props.dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom
                }
                value={
                  props.dealerBackupReportFiltersLastReceivedNonMainBackupDateTo
                }
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastReceivedNonMainBackupDateTo(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastReceivedNonMainBackupDateTo(
                      null,
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
              <DateTimePicker
                label="Last DC request date from"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                maxDate={props.dealerBackupReportFiltersLastDCRequestDateTo}
                value={props.dealerBackupReportFiltersLastDCRequestDateFrom}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastDCRequestDateFrom(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastDCRequestDateFrom(
                      null,
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
              <DateTimePicker
                label="Last DC request date to"
                inputFormat="DD.MM.YY HH:mm"
                ampm={false}
                minutesStep={1}
                minDate={props.dealerBackupReportFiltersLastDCRequestDateFrom}
                value={props.dealerBackupReportFiltersLastDCRequestDateTo}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {justifyContent: 'flex-end'},
                  },
                }}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    props.setDealerBackupReportFiltersLastDCRequestDateTo(
                      dayjs(newValue).utc().format(),
                    );
                  }

                  if (newValue === null) {
                    props.setDealerBackupReportFiltersLastDCRequestDateTo(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      ...calendarStyles,
                      width: '25%',
                      '&>.MuiFormLabel-root ': {
                        pt: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Select start date',
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
            </Box>
          </>
        ) : null}
      </Box>
    </ThemeProvider>
  );
};

export default ReportsTopToolbar;
