import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';
import {
  ArrowAsc,
  ArrowDesc,
  CloseIcon,
  DefaultSortIcon,
  DeleteIcon,
  EditIcon,
  ErrorNotification,
  MoreIcon,
  PlusIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import {IMetadata, ISnackbarState} from 'store/models/common';
import React, {FC, memo, useState} from 'react';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from '../../../styles/MUIStyles/snackbars';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import {
  useDeleteStationMutation,
  useRecoverStationMutation,
  useUploadManualStationFileMutation,
} from '../../../store/slices/stations';
import {useGetNumberOfElements, useUpdateEffect} from 'hooks';

import Guard from '../../../components/Guard/Guard';
import {IGetStationsList as IGetStation} from 'store/models/stations';
import {ISortConfig} from 'hooks/useSortColumn';
import StationsAddBranch from '../StationsAddBranch/StationsAddBranch';
import StationsAddCompany from '../StationsAddCompany/StationsAddCompany';
import {UserRole} from '../../../enums';
import WarningModal from '../../../components/WarningModal/WarningModal';
import styles from './StationsTable.module.scss';
import {useNavigate} from 'react-router-dom';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  stations: IGetStation[];
  metaData: IMetadata | null;
  isRequestWithDeletedStatusSuccess: boolean;
  isRequestWithNotAttachedStatusSuccess: boolean;
  search: string;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
}

const StationsTable: FC<Props> = memo((props) => {
  const navigate = useNavigate();

  const [
    deleteStation,
    {
      isLoading: isDeletingStationLoading,
      isSuccess: isDeletingStationSuccess,
      error: deleteStationError,
    },
  ] = useDeleteStationMutation();

  const [manualStationUploadFile, {}] = useUploadManualStationFileMutation();

  const [
    recoverStation,
    {
      isLoading: isRecoveringStationLoading,
      isSuccess: isRecoveringStationSuccess,
      //   error: recoverStationError,
    },
  ] = useRecoverStationMutation();

  const itemsOnPage = useGetNumberOfElements(props.metaData);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAddCompanyToStationModalOpen, setIsAddCompanyToStationModalOpen] =
    useState(false);
  const [isAddBranchToStationModalOpen, setIsAddBranchToCompanyModalOpen] =
    useState(false);
  const [isDeleteStationModalOpen, setIsDeleteStationModalOpen] =
    useState(false);
  const [isRecoverStationModalOpen, setIsRecoverStationModalOpen] =
    useState(false);
  const [clickedStation, setClickedStation] = useState<IGetStation | null>(
    null,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  //TODO Отрефакторить снекбары
  const [deleteStationSnackbar, setDeleteStationSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [deleteStationSnackbarError, setDeleteStationSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [recoverStationSnackbar, setRecoverStationSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [successUploadnackbar, setSuccessUploadSnackbar] =
    React.useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: deleteVertical,
    horizontal: deleteHorizontal,
    open: deleteOpen,
  } = deleteStationSnackbar;
  const {
    vertical: recoverVertical,
    horizontal: recoverHorizontal,
    open: recoverOpen,
  } = recoverStationSnackbar;
  const {
    vertical: deleteVerticalError,
    horizontal: deleteHorizontalError,
    open: deleteOpenError,
  } = deleteStationSnackbarError;
  //TODO Отрефакторить снекбары
  //TODO Нужно ещё показывать ошибку при восстановлении

  const handleMoreDotsClose = () => setAnchorEl(null);
  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  const handleManualStationUploadFile = (id: string) => {
    const FileInput = window.document.createElement('input');
    FileInput.type = 'file';
    FileInput.click();
    FileInput.onchange = async (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target && target.files && target.files.length > 0) {
        const file = target.files[0];
        if (file) {
          const formData = new FormData();
          formData.append('file', file);
          await manualStationUploadFile({
            stationId: id,
            body: formData,
          });
          setSuccessUploadSnackbar({...successUploadnackbar, open: true});
        }
      }
    };
    handleMoreDotsClose();
  };

  const handleSnackbarClose = () => {
    setDeleteStationSnackbar({...deleteStationSnackbar, open: false});
    setDeleteStationSnackbarError({...deleteStationSnackbarError, open: false});
    setRecoverStationSnackbar({...recoverStationSnackbar, open: false});
    setSuccessUploadSnackbar({...successUploadnackbar, open: false});
    setErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (isDeletingStationSuccess) {
      setDeleteStationSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteStationModalOpen(false);
    }

    if (isRecoveringStationSuccess) {
      setRecoverStationSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoverStationModalOpen(false);
    }
  }, [isDeletingStationSuccess, isRecoveringStationSuccess]);

  useUpdateEffect(() => {
    if (deleteStationError) {
      if ('data' in deleteStationError && 'status' in deleteStationError) {
        if (
          deleteStationError.status === 400 ||
          deleteStationError.status === 404
        ) {
          const errors = deleteStationError.data?.errors;

          for (let key in errors) {
            if (Array.isArray(errors[key])) {
              setIsDeleteStationModalOpen(false);
              //@ts-ignore
              setErrorMessage(errors[key]);
              setDeleteStationSnackbarError({
                vertical: 'top',
                horizontal: 'center',
                open: true,
              });
              break;
            }
          }
        }
      }
    }
  }, [deleteStationError]);

  return (
    <div style={{height: '100%'}}>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '21%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('name')}
                >
                  <span>Name</span>
                  {getArrowForSortDirection('name')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('fiscalDeviceId')}
                >
                  <span>Fiscal device id</span>
                  {getArrowForSortDirection('fiscalDeviceId')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '10%'}}>
                <Box
                  sx={tableHeadCell}
                  //   onClick={() => props.handleColumnClick('stationType')}
                >
                  <span>Station Type</span>
                  {/* {getArrowForSortDirection('stationType')} */}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '17%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('posChassicNumber')}
                >
                  <span>PosChassis number</span>
                  {getArrowForSortDirection('posChassicNumber')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '12%'}}>
                <Box sx={tableHeadCell}>
                  <span>Status</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '12%'}}>
                <Box sx={tableHeadCell}>
                  <span style={{textAlign: 'center', margin: '0 auto'}}>
                    Is attached
                  </span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>{null}</Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.stations.map((station, index) => {
              console.log(station.id);
              return (
                <StyledTableRow
                  key={station.id}
                  sx={{cursor: 'pointer'}}
                  onClick={() =>
                    navigate(`/stations/information/${station.id}`)
                  }
                >
                  <StyledTableCell>
                    {itemsOnPage.length ? itemsOnPage[index] : null}
                  </StyledTableCell>
                  <StyledTableCell sx={{wordBreak: 'break-all'}}>
                    {station.name}
                  </StyledTableCell>
                  <StyledTableCell>{station.fiscalDeviceId}</StyledTableCell>
                  <StyledTableCell>
                    {station.stationTypeName || '---'}
                  </StyledTableCell>
                  <StyledTableCell>{station.posChassicNumber}</StyledTableCell>
                  <StyledTableCell>
                    {station.isDeleted && (
                      <span
                        className={`${styles.stationStatus} ${styles.deleted}`}
                      >
                        Removed
                      </span>
                    )}{' '}
                    {props.isRequestWithNotAttachedStatusSuccess ? (
                      <span
                        className={`${styles.stationStatus} ${styles.notAttached}`}
                      >
                        Not Attached
                      </span>
                    ) : (
                      <span
                        className={
                          station.isActive
                            ? `${styles.stationStatus} ${styles.active}`
                            : `${styles.stationStatus} ${styles.inactive}`
                        }
                      >
                        {station.isActive ? 'Active' : 'Inactive'}
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {station.isAttached ? (
                      <span className={styles.attachedYesIndicator}>Yes</span>
                    ) : (
                      <span className={styles.attachedNoIndicator}>No</span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      id="basic-button"
                      aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isMenuOpen ? 'true' : undefined}
                      onClick={(event) => {
                        setSuccessUploadSnackbar({
                          ...successUploadnackbar,
                          open: false,
                        });
                        event.stopPropagation();
                        setClickedStation(station);
                        handleMoreDotsClick(event);
                      }}
                    >
                      <MoreIcon />
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {props.isRequestWithDeletedStatusSuccess ? (
          <MenuItem
            sx={tableMoreDotsMenu}
            onClick={() => {
              handleMoreDotsClose();
              setIsRecoverStationModalOpen(true);
            }}
          >
            <RecoverIcon />
            <span>Recover</span>
          </MenuItem>
        ) : (
          <div>
            <MenuItem
              sx={tableMoreDotsMenu}
              onClick={() => {
                handleMoreDotsClose();
                setIsAddBranchToCompanyModalOpen(true);
              }}
            >
              <PlusIcon />
              <span>Add branch</span>
            </MenuItem>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <MenuItem
                sx={tableMoreDotsMenu}
                onClick={() => {
                  if (clickedStation) {
                    navigate(`/stations/edit/${clickedStation.id}`);
                  }
                }}
              >
                <EditIcon />
                <span>Edit</span>
              </MenuItem>
            </Guard>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <MenuItem
                sx={tableMoreDotsMenu}
                onClick={() =>
                  clickedStation?.id
                    ? handleManualStationUploadFile(clickedStation?.id)
                    : null
                }
              >
                <EditIcon />
                <span>Manual upload file</span>
              </MenuItem>
            </Guard>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <MenuItem
                sx={tableMoreDotsMenu}
                onClick={() => {
                  handleMoreDotsClose();
                  setIsDeleteStationModalOpen(true);
                }}
              >
                <DeleteIcon />
                <span>Remove</span>
              </MenuItem>
            </Guard>
          </div>
        )}
      </Menu>

      <StationsAddCompany
        isOpen={isAddCompanyToStationModalOpen}
        onClose={() => setIsAddCompanyToStationModalOpen(false)}
        clickedStation={clickedStation}
      />
      <StationsAddBranch
        isOpen={isAddBranchToStationModalOpen}
        onClose={() => setIsAddBranchToCompanyModalOpen(false)}
        clickedStation={clickedStation}
      />

      {clickedStation ? (
        <>
          <WarningModal
            isOpen={
              props.isRequestWithDeletedStatusSuccess
                ? isRecoverStationModalOpen
                : isDeleteStationModalOpen
            }
            onClose={() => {
              props.isRequestWithDeletedStatusSuccess
                ? setIsRecoverStationModalOpen(false)
                : setIsDeleteStationModalOpen(false);
            }}
            entityText={clickedStation.name + ' ?'}
            title={`${
              props.isRequestWithDeletedStatusSuccess ? 'Recover' : 'Remove'
            } station`}
            textBlocks={[
              `Are you sure you want to ${
                props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
              } station`,
            ]}
            confirmButtonText={`Yes, ${
              props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
            }`}
            onConfirmButtonClick={() => {
              if (clickedStation?.id) {
                props.isRequestWithDeletedStatusSuccess
                  ? recoverStation(clickedStation.id)
                  : deleteStation(clickedStation.id);
              }
            }}
            loading={isDeletingStationLoading || isRecoveringStationLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: props.isRequestWithDeletedStatusSuccess
                ? recoverVertical
                : deleteVertical,
              horizontal: props.isRequestWithDeletedStatusSuccess
                ? recoverHorizontal
                : deleteHorizontal,
            }}
            autoHideDuration={2500}
            open={
              props.isRequestWithDeletedStatusSuccess ? recoverOpen : deleteOpen
            }
            onClose={handleSnackbarClose}
            key={`${
              props.isRequestWithDeletedStatusSuccess
                ? 'recoverBranch'
                : 'deleteBranch'
            }`}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Station {`"${clickedStation.name}"`} was{' '}
                {props.isRequestWithDeletedStatusSuccess
                  ? 'recovered'
                  : 'removed'}
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
      {successUploadnackbar && clickedStation ? (
        <Snackbar
          anchorOrigin={{
            horizontal: successUploadnackbar.horizontal,
            vertical: successUploadnackbar.vertical,
          }}
          open={successUploadnackbar.open}
          onClose={() =>
            setSuccessUploadSnackbar({...successUploadnackbar, open: false})
          }
          key={successUploadnackbar.horizontal + successUploadnackbar.vertical}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              The file has been uploaded successfully
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: deleteVerticalError,
            horizontal: deleteHorizontalError,
          }}
          autoHideDuration={3500}
          open={deleteOpenError}
          onClose={handleSnackbarClose}
          key={'deleteCompanyError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
});

export default StationsTable;
