import {
  Box,
  LinearProgress,
  Pagination,
  PaginationItem,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import {
  IGetAllBackupsListModified,
  IGetMainBackupsListModified,
} from 'store/models/backups';
import {
  PaginationArrowLeft,
  PaginationArrowRight,
  RefreshIcon,
} from 'assets/icons';
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {
  a11yProps,
  checkAllowedRoles,
  downloadMultipleFiles,
} from '../../core/utils';
import {muiSelectElementsCount, pagination, tab} from 'styles/MUIStyles/common';
import {
  useDebounce,
  useLocalStorage,
  useSortColumn,
  useUpdateEffect,
} from '../../hooks';
import {
  useGetAllBackupsQuery,
  useGetMainBackupsQuery,
  useLazyGetBackupDownloadLinksQuery,
} from '../../store/slices/backups';

import AllBackups from './AllBackups/AllBackups';
import AssignBackup from './AssignBackup/AssignBackup';
import ButtonWithProgress from '../../components/ButtonWithProgress/ButtonWithProgress';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import DownloadBackupsButton from './DownloadBackupsButton/DownloadBackupsButton';
import {IGetBranchesListItem} from 'store/models/branches';
import {IGetCompaniesListItem} from 'store/models/companies';
import LoadingIconSpacer from '../../components/LoadingIconSpacer/LoadingIconSpacer';
import MainBackups from './MainBackups/MainBackups';
import MarkAsDeleteModal from './MarkAsDeleteModal/MarkAsDeleteModal';
import {StationsListItem} from 'store/models/stations';
import {UserRole} from '../../enums';
import dayjs from 'dayjs';
import {goBackButton} from '../../styles/MUIStyles/buttons';
import styles from './Backups.module.scss';
import {useAppSelector} from '../../store/store';
import {useGetBranchesListQuery} from 'store/slices/branches';
import {useGetCompaniesListQuery} from 'store/slices/companies';
import {useGetMainBackupFileTypesQuery} from '../../store/slices/enums';
import {useGetStationsListQuery} from 'store/slices/stations';

const theme = createTheme({...muiSelectElementsCount});

const Backups: FC = () => {
  const userRole = useAppSelector((state) => state.auth.role);
  const [activeTab, setActiveTab] = useState(0);
  const [downloadFileLoading, setDownloadFileLoading] = useState(false);
  const [downloadAllBackupsFileLoading, setDownloadAllBackupsLoading] =
    useState(false);
  const [isAssignBackupModalOpen, setIsAssignBackupModalOpen] = useState(false);
  const [isMarkAsDeletedModalOpen, setIsMarkAsDeletedModaModalOpen] =
    useState(false);
  const [selectedBackupFile, setSelectedBackupFile] =
    useState<IGetMainBackupsListModified | null>(null);

  const openAssignBackupModalHandler = (backupFile: any) => {
    setSelectedBackupFile(backupFile);
    setIsAssignBackupModalOpen(true);
  };
  const openMarkAsDeletedModalHandler = (backupFile: any) => {
    setSelectedBackupFile(backupFile);
    setIsMarkAsDeletedModaModalOpen(true);
  };

  const closeAssignBackupModalHandler = () => {
    setIsAssignBackupModalOpen(false);
  };

  const {data: backupsFileTypes} = useGetMainBackupFileTypesQuery();

  // all backups start
  const [modifiedAllBackups, setModifiedAllBackups] = useState<
    IGetAllBackupsListModified[]
  >([]);

  const [shouldSaveAllBackupsFileType, setShouldSaveAllBackupsFileType] =
    useLocalStorage('shouldSaveAllBackupsFileType', true);
  const [allBackupFileType, setAllBackupFileType] = useLocalStorage(
    'allBackupsFileType',
    null,
  );

  const [companiesForAllBackups, setCompaniesForAllBackups] = useLocalStorage<
    IGetCompaniesListItem[] | null
  >('companiesForAllBackups', []);
  const [branchesForAllBackups, setBranchessForAllBackups] = useLocalStorage<
    IGetBranchesListItem[] | null
  >('branchesForAllBackups', []);
  const [stationsForAllBackups, setStationsForAllBackups] = useLocalStorage<
    StationsListItem[] | null
  >('stationsForAllBackups', null);
  const [sortConfig, handleColumnClick] = useSortColumn(
    'allBackupsSortConfig',
    {
      column: 'createdDateTime',
      direction: 'desc',
    },
  );
  const [allBackupsStartDate, setAllBackupsStartDate] = useLocalStorage<
    string | null
  >(
    'allBackupsStartDate',
    dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).utc().format(),
  );
  const [allBackupsFinishDate, setAllBackupsFinishDate] = useLocalStorage<
    string | null
  >(
    'allBackupsFinishDate',
    dayjs().set('hour', 23).set('minute', 59).utc().format(),
  );
  const [allBackupsSearch, setAllBackupsSearch] = useLocalStorage(
    'allBackupsSearch',
    '',
  );
  const [allBackupsPageNumber, setAllBackupsPageNumber] = useLocalStorage(
    'allBackupsPageNumber',
    1,
  );
  const [allBackupsPageSize, setAllBackupsPageSize] = useLocalStorage(
    'allBackupsPageSize',
    10,
  );
  const allBackupsDebouncedSearch = useDebounce(allBackupsSearch, 800);
  // all backups end

  // main backups start
  const [mainBackupFileType, setMainBackupFileType] = useLocalStorage(
    'mainBackupsFileType',
    null,
  );
  const [modifiedMainBackups, setModifiedMainBackups] = useState<
    IGetMainBackupsListModified[]
  >([]);
  const [companiesForMainBackups, setCompaniesForMainBackups] = useLocalStorage<
    IGetCompaniesListItem[] | null
  >('companiesForMainBackups', []);
  const [branchesForMainBackups, setBranchesForMainBackups] = useLocalStorage<
    IGetBranchesListItem[] | null
  >('branchesForMainBackups', []);
  const [stationsForMainBackups, setStationsForMainBackups] = useLocalStorage<
    StationsListItem[] | null
  >('stationsForMainBackups', null);

  const [mainBackupSortConfig, mainBackupHandleColumnClick] = useSortColumn(
    'mainBackupsSortConfig',
    {
      column: 'createdDateTime',
      direction: 'desc',
    },
  );
  const [mainBackupsStartDate, setMainBackupsStartDate] = useLocalStorage<
    string | null
  >(
    'mainBackupsStartDate',
    dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).utc().format(),
  );
  const [mainBackupsFinishDate, setMainBackupsFinishDate] = useLocalStorage<
    string | null
  >(
    'mainBackupsFinishDate',
    dayjs().set('hour', 23).set('minute', 59).utc().format(),
  );

  const [mainEditBackupsStartDate, setMainEditBackupsStartDate] =
    useLocalStorage<string | null>(
      'mainEditBackupsStartDate',
      dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).utc().format(),
    );
  const [mainEditBackupsFinishDate, setMainEditBackupsFinishDate] =
    useLocalStorage<string | null>(
      'mainEditBackupsFinishDate',
      dayjs().set('hour', 23).set('minute', 59).utc().format(),
    );

  const [mainBackupsSearch, setMainBackupsSearch] = useLocalStorage(
    'allBackupsSearch',
    '',
  );
  const [mainBackupsPageNumber, setMainBackupsPageNumber] = useLocalStorage(
    'mainBackupsPageNumber',
    1,
  );
  const [mainBackupsPageSize, setMainBackupsPageSize] = useLocalStorage(
    'mainBackupsPageSize',
    10,
  );
  const mainBackupsDebouncedSearch = useDebounce(mainBackupsSearch);
  //main backups end

  const {data: companies} = useGetCompaniesListQuery();
  const {data: branches} = useGetBranchesListQuery(
    activeTab === 0 ? companiesForMainBackups : companiesForAllBackups,
  );
  const {data: stations} = useGetStationsListQuery({
    branches: activeTab === 0 ? branchesForMainBackups : branchesForAllBackups,
    companies:
      activeTab === 0 ? companiesForMainBackups : companiesForAllBackups,
  });
  const {
    data: mainBackups,
    refetch: mainBackupsRefetch,
    isFetching: isGettingMainBackupsLoading,
  } = useGetMainBackupsQuery({
    sortBy: mainBackupSortConfig,
    search: mainBackupsDebouncedSearch,
    pageSize: mainBackupsPageSize,
    pageNumber: mainBackupsPageNumber,
    // startDateTime: mainBackupsStartDate,
    // endDateTime: mainBackupsFinishDate,
    editDateTime: mainEditBackupsStartDate,
    editEndDateTime: mainEditBackupsFinishDate,
    mainBackupFileType,
    companies: companiesForMainBackups,
    branches: branchesForMainBackups,
    stations: stationsForMainBackups,
    userRole: userRole as UserRole,
  });

  const {
    data: allBackups,
    refetch: allBackupsRefetch,
    isFetching: isGettingAllBackupsLoading,
  } = useGetAllBackupsQuery(
    {
      sortBy: sortConfig,
      search: allBackupsDebouncedSearch,
      pageSize: allBackupsPageSize,
      pageNumber: allBackupsPageNumber,
      startDateTime: allBackupsStartDate,
      endDateTime: allBackupsFinishDate,
      companies: companiesForAllBackups,
      branches: branchesForAllBackups,
      stations: stationsForAllBackups,
      userRole: userRole as UserRole,
    },
    {
      skip: !userRole
        ? true
        : userRole === UserRole.Client || userRole === UserRole.Owner
          ? true
          : undefined,
    },
  );

  const [trigger, result] = useLazyGetBackupDownloadLinksQuery();

  const downloadBackupFiles = async (backupIds: number[]) => {
    try {
      const {data} = await trigger(backupIds);

      downloadMultipleFiles(data as string[], setDownloadFileLoading);
    } catch (error) {
      console.log('mnogo filov', error);
    }
  };

  const openInNewTabHandler = async (backupIds: number[]) => {
    const {data} = await trigger(backupIds);

    if (data && data[0]) {
      window.open(data[0], '_blank', 'noopener,noreferrer');
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleAllBackupsOnPageChange = (
    event: ChangeEvent<unknown>,
    page: number,
  ) => {
    setAllBackupsPageNumber(page);
  };

  const handleMainBackupsOnPageChange = (
    event: ChangeEvent<unknown>,
    page: number,
  ) => {
    setMainBackupsPageNumber(page);
  };

  useEffect(() => {
    if (allBackups?.data) {
      setModifiedAllBackups(
        allBackups.data.map((backup) => ({...backup, isChecked: false})),
      );
    }
  }, [allBackups]);

  useEffect(() => {
    if (mainBackups?.data) {
      setModifiedMainBackups(
        mainBackups.data.map((backup) => ({...backup, isChecked: false})),
      );
    }
  }, [mainBackups]);

  useUpdateEffect(() => {
    const setBackupFileType = (
      fileTypeSetter: (file: {
        id: number;
        name: string;
        description: string;
      }) => void,
    ) => {
      if (backupsFileTypes?.length) {
        const defaultFileType = backupsFileTypes.find(
          (type) => type.name === 'TXT',
        );
        if (defaultFileType) {
          fileTypeSetter(defaultFileType);
        }
      }
    };

    if (!mainBackupFileType && shouldSaveAllBackupsFileType) {
      setBackupFileType(setMainBackupFileType);
    }

    if (!allBackupFileType) {
      setBackupFileType(setAllBackupFileType);
    }
  }, [backupsFileTypes]);

  useUpdateEffect(() => {
    setMainBackupsPageNumber(1);
  }, [
    mainBackupsDebouncedSearch,
    stationsForMainBackups,
    mainBackupsStartDate,
    mainBackupsFinishDate,
    mainEditBackupsFinishDate,
    mainEditBackupsStartDate,
    mainBackupsPageSize,
    mainBackupFileType,
  ]);

  useUpdateEffect(() => {
    setAllBackupsPageNumber(1);
  }, [
    allBackupsDebouncedSearch,
    stationsForAllBackups,
    allBackupsStartDate,
    allBackupsFinishDate,
    allBackupsPageSize,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <div className={styles.backupInformationTop}>
          <h1 className={styles.backupInformationTitle}>Backups</h1>
          <div className={styles.buttonsContainer}>
            {downloadFileLoading || downloadAllBackupsFileLoading ? (
              <Box
                sx={{
                  width: '40%',
                  padding: '10px',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  boxShadow: '0px -1px 0px 0px #E4E4EF inset',
                }}
              >
                <p className={styles.fileIsDownloading}>File is downloading</p>
                <LinearProgress
                  sx={{height: '6px', borderRadius: '5px', marginTop: '3px'}}
                />
              </Box>
            ) : null}
            <ButtonWithProgress
              loading={
                isGettingAllBackupsLoading || isGettingMainBackupsLoading
              }
              disabled={
                isGettingAllBackupsLoading || isGettingMainBackupsLoading
              }
              onClick={() => {
                if (
                  userRole === UserRole.Admin ||
                  userRole === UserRole.Dealer
                ) {
                  allBackupsRefetch();
                }
                mainBackupsRefetch();
              }}
              sx={{
                ...goBackButton,
                '.MuiCircularProgress-root': {
                  color: '#1976d2 !important',
                },
              }}
              startIcon={
                isGettingMainBackupsLoading || isGettingAllBackupsLoading ? (
                  <LoadingIconSpacer width={'16px'} height={'16px'} />
                ) : (
                  <RefreshIcon
                    style={{
                      width: '16px',
                      height: '16px',
                      filter:
                        'invert(24%) sepia(76%) saturate(2109%) hue-rotate(218deg) brightness(91%) contrast(90%)',
                    }}
                  />
                )
              }
            >
              Refresh
            </ButtonWithProgress>
            <DownloadBackupsButton
              modifiedMainBackups={modifiedMainBackups}
              modifiedAllBackups={modifiedAllBackups}
              activeTab={activeTab}
              downloadHandler={downloadBackupFiles}
              loading={result.isFetching}
              downloadFileLoading={downloadFileLoading}
              setDownloadFileLoading={setDownloadFileLoading}
            />
          </div>
        </div>
        <div className={styles.backupInformationMainContent}>
          <Box sx={{height: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
              }}
              value={activeTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Main backups" {...a11yProps(0)} />
              {checkAllowedRoles(
                [UserRole.Admin, UserRole.Dealer],
                userRole,
              ) && <Tab sx={tab} label="All backups" {...a11yProps(1)} />}
            </Tabs>
            <div style={{height: '93%'}}>
              <CustomTabPanel value={activeTab} index={0}>
                <MainBackups
                  setMainEditBackupsStartDate={setMainEditBackupsStartDate}
                  mainEditBackupsStartDate={mainEditBackupsStartDate}
                  setMainEditBackupsFinishDate={setMainEditBackupsFinishDate}
                  mainEditBackupsFinishDate={mainEditBackupsFinishDate}
                  modifiedMainBackups={modifiedMainBackups}
                  setModifiedMainBackups={setModifiedMainBackups}
                  stationsForMainBackups={stations || []}
                  selectedStationsForMainBackups={stationsForMainBackups}
                  setSelectedStationsForMainBackups={setStationsForMainBackups}
                  metaData={mainBackups?.metadata || null}
                  mainBackupSortConfig={mainBackupSortConfig}
                  mainBackupHandleColumnClick={mainBackupHandleColumnClick}
                  search={mainBackupsSearch}
                  setSearch={setMainBackupsSearch}
                  mainBackupsDebouncedSearch={mainBackupsDebouncedSearch}
                  mainBackupsStartDate={mainBackupsStartDate}
                  setMainBackupsStartDate={setMainBackupsStartDate}
                  mainBackupsFinishDate={mainBackupsFinishDate}
                  setMainBackupsFinishDate={setMainBackupsFinishDate}
                  mainBackupsPageSize={mainBackupsPageSize}
                  setMainBackupsPageSize={setMainBackupsPageSize}
                  backupsFileTypes={backupsFileTypes || []}
                  mainBackupFileType={mainBackupFileType}
                  setMainBackupFileType={setMainBackupFileType}
                  downloadFileHandler={downloadBackupFiles}
                  openInNewTabHandler={openInNewTabHandler}
                  companies={companies || []}
                  selectedCompanies={companiesForMainBackups}
                  setSelectedCompanies={setCompaniesForMainBackups}
                  branches={branches || []}
                  selectedBranches={branchesForMainBackups}
                  setSelectedBranches={setBranchesForMainBackups}
                  setShouldSaveAllBackupsFileType={
                    setShouldSaveAllBackupsFileType
                  }
                  openAssignBackupModalHandler={openAssignBackupModalHandler}
                  openMarkAsDeletedModalHandler={openMarkAsDeletedModalHandler}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={1}>
                <AllBackups
                  modifiedAllBackups={modifiedAllBackups}
                  setModifiedAllBackups={setModifiedAllBackups}
                  metaData={allBackups?.metadata || null}
                  stationsForAllBackups={stations || []}
                  selectedStationsForAllBackups={stationsForAllBackups}
                  setSelectedStationsForAllBackups={setStationsForAllBackups}
                  sortConfig={sortConfig}
                  handleColumnClick={handleColumnClick}
                  search={allBackupsSearch}
                  setSearch={setAllBackupsSearch}
                  allBackupsDebouncedSearch={allBackupsDebouncedSearch}
                  allBackupsStartDate={allBackupsStartDate}
                  setAllBackupsStartDate={setAllBackupsStartDate}
                  allBackupsFinishDate={allBackupsFinishDate}
                  setAllBackupsFinishDate={setAllBackupsFinishDate}
                  allBackupsPageSize={allBackupsPageSize}
                  setAllBackupsPageSize={setAllBackupsPageSize}
                  backupsFileTypes={backupsFileTypes || []}
                  allBackupFileType={allBackupFileType}
                  setAllBackupFileType={setAllBackupFileType}
                  downloadAllBackupsFileLoading={downloadAllBackupsFileLoading}
                  setDownloadAllBackupsLoading={setDownloadAllBackupsLoading}
                  companies={companies || []}
                  selectedCompanies={companiesForAllBackups}
                  setSelectedCompanies={setCompaniesForAllBackups}
                  branches={branches || []}
                  selectedBranches={branchesForAllBackups}
                  setSelectedBranches={setBranchessForAllBackups}
                />
              </CustomTabPanel>
            </div>
          </Box>
        </div>
        <div className={styles.pagination}>
          {activeTab === 0 ? (
            <Pagination
              count={mainBackups?.metadata?.pageCount || 1}
              page={mainBackupsPageNumber}
              onChange={handleMainBackupsOnPageChange}
              sx={pagination}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: PaginationArrowLeft,
                    next: PaginationArrowRight,
                  }}
                  {...item}
                />
              )}
            />
          ) : (
            <Pagination
              count={allBackups?.metadata?.pageCount || 1}
              page={allBackupsPageNumber}
              onChange={handleAllBackupsOnPageChange}
              sx={pagination}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: PaginationArrowLeft,
                    next: PaginationArrowRight,
                  }}
                  {...item}
                />
              )}
            />
          )}
        </div>
      </div>
      <AssignBackup
        isOpen={isAssignBackupModalOpen}
        onClose={closeAssignBackupModalHandler}
        stationsList={stations || []}
        selectedBackupFile={selectedBackupFile}
      />
      <MarkAsDeleteModal
        isOpen={isMarkAsDeletedModalOpen}
        onClose={setIsMarkAsDeletedModaModalOpen}
        selectedBackupFile={selectedBackupFile}
      />
    </ThemeProvider>
  );
};

export default Backups;
