import {FC, useState} from 'react';
import {useUpdateEffect} from 'hooks';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Snackbar,
  SnackbarOrigin,
  TextField,
} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {CloseIcon, SelectPopupIcon, SuccessNotification} from 'assets/icons';
import {searchAndSelect, searchAndSelectError} from 'styles/MUIStyles/common';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {modal} from 'styles/MUIStyles/modal';
import styles from './AssignBackup.module.scss';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from '../../../styles/MUIStyles/snackbars';
import {StationsListItem} from 'store/models/stations';
import {useAssignFileToAnotherStationMutation} from 'store/slices/backups';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  stationsList: StationsListItem[];
  selectedBackupFile: any;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const AssignBackup: FC<Props> = ({
  isOpen,
  onClose,
  stationsList,
  selectedBackupFile,
}) => {
  const [selectedStation, setSelectedStation] =
    useState<StationsListItem | null>(null);
  const [stationError, setStationError] = useState('');
  const [snackbar, setSnackbar] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = snackbar;

  const [assignBackup, result] = useAssignFileToAnotherStationMutation();

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (!selectedStation) {
      setStationError('Station is required');
      return;
    }
    if (!selectedBackupFile) {
      setStationError('File is required');
      return;
    }
    console.log(selectedStation);
    console.log(selectedBackupFile);
    try {
      await assignBackup({
        fileId: selectedBackupFile.id,
        stationId: selectedStation.id,
      }).unwrap();
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
    } catch (error: any) {
      const errorKey = Object.keys(error?.data?.errors)[0];
      setStationError((error as any)?.data.errors[errorKey][0]);
    }
  };

  const handleClose = () => {
    setSelectedStation(null);
    setStationError('');
    onClose();
  };

  const handleSnackbarClose = () => setSnackbar({...snackbar, open: false});

  useUpdateEffect(() => {
    if (isOpen) {
    }
  }, [isOpen]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...modal, width: '40%'}}>
          <form onSubmit={onSubmit}>
            <div className={styles.modalTop}>
              <h2 className={styles.title}>
                Assign backup file to another station
              </h2>
              <CloseIcon onClick={handleClose} />
            </div>
            <Autocomplete
              options={stationsList}
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{width: '100%'}}
              ListboxProps={{
                style: {maxHeight: '200px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                setStationError('');
                setSelectedStation(value);
              }}
              renderOption={(props: any, option) => (
                <span {...props} className={styles.dropDownItem}>
                  {option?.name} / {option?.posChassicNumber}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select station"
                  sx={!!stationError ? searchAndSelectError : searchAndSelect}
                  error={!!stationError}
                  helperText={stationError}
                />
              )}
            />
            <Divider
              sx={{backgroundColor: '#E4E4EF', margin: '18px 0 14px 0'}}
            />
            <div className={styles.buttonsContainer}>
              <Button type="button" sx={cancelButton} onClick={handleClose}>
                Cancel
              </Button>
              <ButtonWithProgress
                type="submit"
                sx={{...createButtonModal}}
                loading={result.isLoading}
                disabled={result.isLoading}
              >
                Assign
              </ButtonWithProgress>
            </div>
          </form>
        </Box>
      </Modal>
      {selectedStation && result.data ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Backup file {selectedBackupFile?.fileName} successfully assigned
              to station {selectedStation?.name}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default AssignBackup;
